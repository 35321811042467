<div class="session-item" 
     [class.expanded]="isExpanded" 
     [class.is-folder]="group.type === 'folder'"
     [class.is-empty]="group.type === 'folder' && !hasChildren"
     (click)="group.type === 'folder' ? onFolderClick.emit(group) : null">
  <div class="session-header">
    <div class="session-info">
      <div class="session-title">
        <div class="icon-container">
          <mat-icon class="item-icon">{{group.type === 'folder' ? 'folder' : (group.records[0]?.icon || 'description')}}</mat-icon>
        </div>
        {{getDisplayTitle()}}
      </div>
      <p *ngIf="isExpanded && group.type !== 'folder'" class="description">{{group.description}}</p>
    </div>

    <button mat-icon-button 
            class="pin-button" 
            *ngIf="group.type === 'folder'"
            (click)="$event.stopPropagation(); onPinToggle.emit(group.id)">
      <mat-icon>{{group.isPinned ? 'push_pin' : 'push_pin_outlined'}}</mat-icon>
    </button>

    <button mat-icon-button 
            class="expand-button" 
            *ngIf="group.type !== 'folder' || hasChildren"
            (click)="$event.stopPropagation(); toggleExpand()">
      <mat-icon>
        {{group.type === 'folder' ? 'chevron_right' : (isExpanded ? 'expand_less' : 'expand_more')}}
      </mat-icon>
    </button>
  </div>

  <div class="session-content" *ngIf="isExpanded && group.type !== 'folder'">
    <div class="tags" *ngIf="group.tags?.length">
      <mat-chip-listbox>
        <mat-chip *ngFor="let tag of group.tags" 
                 class="tag-chip">
          <mat-icon class="tag-icon">local_offer</mat-icon>
          {{tag.label}}
        </mat-chip>
      </mat-chip-listbox>
    </div>

    <div class="record-details">
      <div class="record-info">
        <span class="date">{{group.date | date:'medium'}}</span>
        <p class="description">{{group.description}}</p>
      </div>
      <div class="record-actions">
        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>