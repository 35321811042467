// src/app/components/session/input-box/input-box.component.ts
import { Component, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatIconModule]
})
export class InputBoxComponent implements AfterViewInit {
  @Input() isProcessing: boolean = false;
  @Output() messageSent = new EventEmitter<string>();
  @ViewChild('textArea') textArea!: ElementRef<HTMLTextAreaElement>;
  
  message: string = '';
  private composing: boolean = false;

  ngAfterViewInit() {
    this.adjustTextarea();
  }

  onSubmit(): void {
    if (this.message.trim() && !this.isProcessing) {
      this.messageSent.emit(this.message);
      this.message = '';
      // Reset textarea height to minimum
      if (this.textArea) {
        this.textArea.nativeElement.style.height = '34px';
      }
    }
  }

  onAttachFile(): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.txt,.pdf,.doc,.docx,image/*';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        console.log('File selected:', file);
      }
    };
    input.click();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Process') {
      this.composing = true;
      return;
    }
    
    if (event.key === 'Enter' && !event.shiftKey && !this.composing) {
      event.preventDefault();
      this.onSubmit();
    }
    
    if (this.composing && event.key === 'Enter') {
      this.composing = false;
    }
  }

  adjustTextarea(): void {
    const textarea = this.textArea.nativeElement;
    // Reset to minimum height
    textarea.style.height = '34px';
    // Calculate required height
    const newHeight = Math.min(textarea.scrollHeight, 355);
    textarea.style.height = `${newHeight}px`;
  }
}

export default InputBoxComponent;