<app-sidebar
  [config]="sidebarConfig"
  [actions]="sidebarActions"
  (stateChange)="onStateChange($event)">
  <ng-template #sidebarContent>
    <div class="properties-content">
      <mat-tab-group [(selectedIndex)]="selectedTab" *ngIf="currentArtifact">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>{{ tabs[0].icon }}</mat-icon>
            <span>{{ tabs[0].label }}</span>
          </ng-template>
          <!-- Overview content -->
          <div class="tab-content">
            <h3>{{ currentArtifact.title }}</h3>
            <div class="metadata">
              <div class="metadata-item">
                <mat-icon>description</mat-icon>
                <span>Type: {{ currentArtifact.type }}</span>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>{{ tabs[1].icon }}</mat-icon>
            <span>{{ tabs[1].label }}</span>
          </ng-template>
          <!-- Preview content -->
          <div class="tab-content">
            <div class="artifact-preview">
              <pre class="artifact-content">{{ currentArtifact.content }}</pre>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div class="empty-state" *ngIf="!currentArtifact">
        <mat-icon>info</mat-icon>
        <p>Select an artifact to view its details</p>
      </div>
    </div>
  </ng-template>
</app-sidebar>