// sidebar.component.ts
import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SidebarAction, SidebarConfig } from '../../../models/sidebar.interface';
import { SafeHtmlPipe } from '../../../pipes/safe-html.pipe';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    SafeHtmlPipe
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() config: SidebarConfig = {
    position: 'left',
    defaultWidth: 570,
    minWidth: 200,
    maxWidth: 900
  };
  @Input() actions: SidebarAction[] = [];
  @Input() content: any;  // Template reference for the sidebar content

  @Output() stateChange = new EventEmitter<boolean>();

  width: number;
  isResizing = false;
  startX = 0;
  startWidth = 0;

  constructor(private elementRef: ElementRef) {
    this.width = this.config.defaultWidth;
  }

  isDocked = false;
  isHovered = false;
  private shouldShowFromTriggerZone = false;  // New property to handle trigger zone visibility

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isResizing) {
      if (!this.isDocked) {
        const triggerZone = 50;
        const mouseX = event.clientX;
        
        if (this.config.position === 'left') {
          this.shouldShowFromTriggerZone = mouseX <= triggerZone;
        } else {
          this.shouldShowFromTriggerZone = mouseX >= window.innerWidth - triggerZone;
        }
      }
      return;
    }
    // ... rest of mouse move logic
  }

  get isVisible(): boolean {
    return this.isDocked || this.isHovered || this.shouldShowFromTriggerZone;
  }

  onSidebarMouseEnter() {
    this.isHovered = true;
  }

  onSidebarMouseLeave() {
    this.isHovered = false;
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.isResizing = false;
  }

  startResize(event: MouseEvent) {
    this.isResizing = true;
    this.startX = event.clientX;
    this.startWidth = this.width;
  }

  toggleDock() {
    this.isDocked = !this.isDocked;
    if (this.isDocked) {
      this.shouldShowFromTriggerZone = false;
      this.isHovered = false;
    }

    this.stateChange.emit(this.isDocked);
  }

  public getDockIcon(): string {
    return this.isDocked ? 
      `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256">
        <path d="M232,128a8,8,0,0,1-8,8H91.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L91.31,120H224A8,8,0,0,1,232,128ZM40,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,40,32Z"></path>
      </svg>` :
      `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256">
        <path d="M189.66,122.34a8,8,0,0,1,0,11.32l-72,72a8,8,0,0,1-11.32-11.32L164.69,136H32a8,8,0,0,1,0-16H164.69L106.34,61.66a8,8,0,0,1,11.32-11.32ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z"></path>
      </svg>`;
  }
}