import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Artifact } from '../models/artifact.model';

interface PropertiesBarState {
  artifact: Artifact | null;
  isDocked: boolean;
  isExpanded: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PropertiesBarService {
  private initialState: PropertiesBarState = {
    artifact: null,
    isDocked: false,
    isExpanded: false
  };

  private stateSubject = new BehaviorSubject<PropertiesBarState>(this.initialState);
  state$ = this.stateSubject.asObservable();

  constructor() {}

  showArtifact(artifact: Artifact) {
    this.stateSubject.next({
      artifact,
      isDocked: true,
      isExpanded: true
    });
  }

  clearArtifact() {
    this.stateSubject.next({
      ...this.initialState
    });
  }

  setState(state: Partial<PropertiesBarState>) {
    const currentState = this.stateSubject.value;
    const newState = {
      ...currentState,
      ...state
    };

    // If undocking, also clear the artifact
    if (currentState.isDocked && !newState.isDocked) {
      newState.artifact = null;
      newState.isExpanded = false;
    }

    this.stateSubject.next(newState);
  }
}
