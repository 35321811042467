import { Component, Output, EventEmitter, OnInit, OnDestroy, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { PropertiesBarService } from '../../../services/properties-bar.service';
import { Artifact } from '../../../models/artifact.model';
import { Subscription } from 'rxjs';

import { SidebarAction, SidebarConfig } from '../../../models/sidebar.interface';

@Component({
  selector: 'app-properties-bar',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    SidebarComponent
  ],
  templateUrl: './properties-bar.component.html',
  styleUrls: ['./properties-bar.component.scss']
})
export class PropertiesBarComponent implements OnInit, OnDestroy {
    
  @Output() stateChange = new EventEmitter<boolean>();
  @ViewChild(SidebarComponent) sidebar!: SidebarComponent;
  @ViewChild('sidebarContent') sidebarContent!: TemplateRef<any>;
    
  sidebarConfig: SidebarConfig = {
    position: 'right',
    defaultWidth: 300,
    minWidth: 200,
    maxWidth: 600
  } as const;

  sidebarActions: SidebarAction[] = [];

  tabs = [
    { label: 'Overview', icon: 'dashboard' },
    { label: 'Preview', icon: 'visibility' }
  ];

  selectedTab = 0;
  currentArtifact: Artifact | null = null;
  isDocked = false;
  isExpanded = false;
  private subscription: Subscription;

  constructor(
    private propertiesBarService: PropertiesBarService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription = this.propertiesBarService.state$.subscribe(state => {
      this.currentArtifact = state.artifact;
      this.isDocked = state.isDocked;
      this.isExpanded = state.isExpanded;
      
      if (state.artifact) {
        this.selectedTab = 1; // Switch to Preview tab when artifact is shown
        if (this.sidebar) {
          this.sidebar.isDocked = true;
          this.sidebar.content = this.sidebarContent;
          this.cdr.detectChanges();
        }
      }
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.sidebar && this.sidebarContent) {
      this.sidebar.content = this.sidebarContent;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onStateChange(isDocked: boolean) {
    // If undocking and there's an artifact, clear it
    if (!isDocked && this.currentArtifact) {
      this.propertiesBarService.clearArtifact();
    }
    this.isDocked = isDocked;
    this.stateChange.emit(isDocked);
  }
}