import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { RecordsGroup, RecordGroupList, Record } from '../models/record-management.interface';
import { environment } from '../../environments/environment';

interface ServerResponse {
  Items: Array<{
    id: string;
    type: string;
    artifactcontainerreference: string | null;
    displayname: string | null;
    description: string | null;
    createdat: number;
    modifiedat: number;
    status: string;
    tags: string[];
    PartitionKey?: string;
  }>;
  ContinuationToken: string | null;
  TotalCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class RecordManagementService {
  private readonly artifactsApiUrl = environment.artifactsApiUrl;
  private recordGroupLists = new BehaviorSubject<RecordGroupList[]>([]);
  private currentPath = new BehaviorSubject<string>('path_/');
  private currentContent = new BehaviorSubject<RecordGroupList | null>(null);
  private folderCache = new Map<string, boolean>();
  private pinnedGroups = new Set<string>();
  private expandedGroups = new Set<string>();

  private getPartitionKeyForFolders(path: string): string {
    if (path === 'path_/') return 'path_/';
    
    // Remove path_ prefix and clean up slashes
    const cleanPath = path
      .replace(/path_/g, '') // Remove all path_ occurrences
      .replace(/^\/+|\/+$/g, '');
    
    return `path_${cleanPath}`;
  }

  private getPartitionKeyForFiles(path: string): string {
    if (path === 'path_/') return 'tag_';
    
    // Remove path_ prefix and clean up slashes
    const cleanPath = path
      .replace(/path_/g, '') // Remove all path_ occurrences
      .replace(/^\/+|\/+$/g, '');
    
    return `tag_${cleanPath}`;
  }

  private getFolderDisplayName(id: string): string {
    // Remove path_ prefix and clean up slashes
    const name = id
      .replace(/path_/g, '') // Remove all path_ occurrences
      .replace(/^\/+|\/+$/g, '');
    
    const displayName = name.charAt(0).toUpperCase() + name.slice(1);
    console.log('Folder display name:', { id, name, displayName });
    return displayName;
  }

  private createFolderRecord(item: ServerResponse['Items'][0]): Record {
    return {
      id: item.id,
      icon: 'folder',
      name: this.getFolderDisplayName(item.id),
      description: item.description || `${this.getFolderDisplayName(item.id)} folder`
    };
  }

  private createFileRecord(item: ServerResponse['Items'][0]): Record {
    const tags = item.tags?.map(tag => {
      const parts = tag.split('/');
      return {
        id: tag,
        label: parts[parts.length - 1],
        description: tag,
        color: '#2196F3'
      };
    }) || [];

    return {
      id: item.id,
      icon: this.getIconForType(item.type),
      name: item.displayname || item.id,
      description: item.description || '',
      tags
    };
  }

  private getIconForType(type: string): string {
    switch (type) {
      case 'Transform':
        return 'transform';
      case 'Connection':
        return 'link';
      default:
        return 'description';
    }
  }

  constructor(private http: HttpClient) {
    // Subscribe to path changes to load content
    this.currentPath.subscribe(path => {
      this.loadContent(path);
    });
    this.loadContent('path_/');
  }

  getRecordGroupLists(): Observable<RecordGroupList[]> {
    return this.recordGroupLists.asObservable();
  }

  getCurrentPath(): Observable<string> {
    return this.currentPath.asObservable();
  }

  getCurrentContent(): Observable<RecordGroupList | null> {
    return this.currentContent.asObservable();
  }

  private async loadContent(path: string) {
    console.log('Loading content for path:', path);
    
    // Get folders
    const folderQueryBody = {
      artifactcontainerreference: environment.documentsContainerRef,
      partitionKey: this.getPartitionKeyForFolders(path),
    };
    console.log('Folder query body:', folderQueryBody);

    const folderResponse = await firstValueFrom(
      this.http.post<ServerResponse>(`${this.artifactsApiUrl}/artifacts/query${environment.keyCode}`, folderQueryBody)
    );
    console.log('Folder response:', folderResponse);

    // Get files
    const fileQueryBody = {
      artifactcontainerreference: environment.documentsContainerRef,
      partitionKey: this.getPartitionKeyForFiles(path),
    };
    console.log('File query body:', fileQueryBody);

    const fileResponse = await firstValueFrom(
      this.http.post<ServerResponse>(`${this.artifactsApiUrl}/artifacts/query${environment.keyCode}`, fileQueryBody)
    );
    console.log('File response:', fileResponse);

    // Process and combine the results
    const content: RecordGroupList = {
      id: path,
      title: path === 'path_/' ? 'Root' : this.getFolderDisplayName(path),
      defaultExpanded: true,
      groups: []
    };
    console.log('Initial content:', content);

    // Add folders as individual groups
    const folderItems = folderResponse.Items
      ?.filter(item => item.type === 'Path' && item.status === 'Active');

    if (folderItems?.length > 0) {
      folderItems.forEach(item => {
        const folderGroup: RecordsGroup = {
          id: item.id,
          title: this.getFolderDisplayName(item.id),
          date: new Date(item.createdat).toISOString(),
          isPinned: false,
          isExpanded: false,
          tags: [],
          records: [],
          type: 'folder'
        };
        console.log('Created folder group:', folderGroup);
        content.groups.push(folderGroup);
        
        // Update folder cache
        this.folderCache.set(item.id.toLowerCase(), true);
        console.log('Updated folder cache:', { id: item.id, hasChildren: true });
      });
    }

    // Add files as individual groups
    const fileItems = fileResponse.Items
      ?.filter(item => item.status === 'Active');

    if (fileItems?.length > 0) {
      fileItems.forEach(item => {
        const fileRecord = this.createFileRecord(item);
        const fileGroup: RecordsGroup = {
          id: item.id,
          title: item.displayname || item.id,
          date: new Date(item.createdat).toISOString(),
          isPinned: false,
          isExpanded: false,
          tags: item.tags?.map(tag => ({
            id: tag,
            label: tag.split('/').pop() || tag,
            description: tag,
            color: '#2196F3'
          })) || [],
          records: [fileRecord],
          type: 'record'
        };
        console.log('Created file group:', fileGroup);
        content.groups.push(fileGroup);
      });
    }

    console.log('Final content to be set:', content);
    this.currentContent.next(content);
  }

  async navigateToFolder(path: string): Promise<void> {
    // Ensure path has correct format
    const cleanPath = path
      .replace(/path_/g, '') // Remove all path_ occurrences
      .replace(/^\/+|\/+$/g, '');
    const formattedPath = path === 'path_/' ? path : `path_${cleanPath}`;
    console.log('Navigating to folder:', { path, cleanPath, formattedPath });
    this.currentPath.next(formattedPath);
  }

  async hasFolderChildren(folderId: string): Promise<boolean> {
    console.log('Checking folder children for:', folderId);
    const path = folderId.toLowerCase();
    
    // Check cache first
    if (this.folderCache.has(path)) {
      const hasChildren = this.folderCache.get(path)!;
      console.log('Found in cache:', { path, hasChildren });
      return hasChildren;
    }

    // Root always has children
    if (path === 'path_/') {
      console.log('Root folder, returning true');
      return true;
    }

    try {
      // Check for subfolders
      const folderQueryBody = {
        artifactcontainerreference: environment.documentsContainerRef,
        partitionKey: this.getPartitionKeyForFolders(path),
      };
      console.log('Folder children query body:', folderQueryBody);

      const folderResponse = await firstValueFrom(
        this.http.post<ServerResponse>(`${this.artifactsApiUrl}/artifacts/query${environment.keyCode}`, folderQueryBody)
      );
      console.log('Folder children response:', folderResponse);

      // Check for files
      const fileQueryBody = {
        artifactcontainerreference: environment.documentsContainerRef,
        partitionKey: this.getPartitionKeyForFiles(path),
      };
      console.log('File children query body:', fileQueryBody);

      const fileResponse = await firstValueFrom(
        this.http.post<ServerResponse>(`${this.artifactsApiUrl}/artifacts/query${environment.keyCode}`, fileQueryBody)
      );
      console.log('File children response:', fileResponse);

      const hasChildren = (folderResponse.Items?.length > 0) || (fileResponse.Items?.length > 0);
      console.log('Has children result:', { path, hasChildren, folderCount: folderResponse.Items?.length, fileCount: fileResponse.Items?.length });
      this.folderCache.set(path, hasChildren);
      return hasChildren;
    } catch (error) {
      console.error('Error checking for folder children:', error);
      return false;
    }
  }

  async toggleGroupPin(groupId: string): Promise<void> {
    if (this.pinnedGroups.has(groupId)) {
      this.pinnedGroups.delete(groupId);
    } else {
      this.pinnedGroups.add(groupId);
    }
    
    const currentContent = this.currentContent.value;
    if (currentContent) {
      const updatedContent = {
        ...currentContent,
        groups: currentContent.groups.map(group => {
          if (group.id === groupId) {
            return { ...group, isPinned: this.pinnedGroups.has(groupId) };
          }
          return group;
        })
      };
      this.currentContent.next(updatedContent);
    }
  }

  async toggleGroupExpansion(groupId: string): Promise<void> {
    if (this.expandedGroups.has(groupId)) {
      this.expandedGroups.delete(groupId);
    } else {
      this.expandedGroups.add(groupId);
    }
    
    const currentContent = this.currentContent.value;
    if (currentContent) {
      const updatedContent = {
        ...currentContent,
        groups: currentContent.groups.map(group => {
          if (group.id === groupId) {
            return { ...group, isExpanded: this.expandedGroups.has(groupId) };
          }
          return group;
        })
      };
      this.currentContent.next(updatedContent);
    }
  }
}
