import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, debounceTime } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ChatSession, SessionStatus } from '../models/chat.models';
import { UserService } from './user.service';
import { Project, Phase, TabReference, TabInfo } from '../models/session.interface';
import { WorkspaceService } from './workspace.service';
import { ChatService } from './chat.service';

// Extended Project type to include phases
interface ProjectWithPhases extends Project {
  phases?: Phase[];
}

// Workspace state interface
interface WorkspaceState {
  projects: ProjectWithPhases[];
  sessions: ChatSession[];
  tabs: TabInfo[];
  openedTabs: string[];
  selectedProject: Project | null;
  selectedPhase: Phase | null;
}

@Injectable({
  providedIn: 'root'
})
export class SessionsService implements OnDestroy {
  private sessions = new BehaviorSubject<ChatSession[]>([]);
  private openedTabs = new BehaviorSubject<string[]>([]);
  private tabs = new BehaviorSubject<TabInfo[]>([]);
  private projects = new BehaviorSubject<Project[]>([]);
  private phases = new BehaviorSubject<Phase[]>([]);
  private selectedProject = new BehaviorSubject<Project | null>(null);
  private selectedPhase = new BehaviorSubject<Phase | null>(null);
  private tabReferences = new BehaviorSubject<Map<string, TabReference>>(new Map());
  private projectPhasesMap = new Map<string, Phase[]>();
  
  private saveSubject = new Subject<void>();
  private initialized = false;

  constructor(
    private userService: UserService,
    private chatService: ChatService,
    private workspaceService: WorkspaceService
  ) {
    console.log('[SessionsService] Initializing service');
    this.saveSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.saveWorkspace();
    });
    this.initializeService();
  }

  private async initializeService(): Promise<void> {
    console.log('[SessionsService] Starting service initialization');
    try {
      const user = await this.userService.getCurrentUser();
      if (!user?.email) {
        console.warn('[SessionsService] No user available during initialization');
        return;
      }

      console.log('[SessionsService] Loading workspace data for user:', user.email);
      const workspaceData = await this.workspaceService.loadWorkspace();

      if (workspaceData?.workspace) {
        console.log('[SessionsService] Found existing workspace data, starting restoration');
        await this.restoreWorkspaceState(workspaceData.workspace);
      } else {
        console.log('[SessionsService] No existing workspace found, starting fresh session');
        await this.startSession(user.email);
      }

      this.initialized = true;
      console.log('[SessionsService] Service initialization completed successfully');
    } catch (error) {
      console.error('[SessionsService] Error during service initialization:', error);
    }
  }

  private async restoreWorkspaceState(workspace: any): Promise<void> {
    console.log('[SessionsService] Starting workspace state restoration');
    
    try {
      if (workspace.projects) {
        console.log('[SessionsService] Restoring projects:', workspace.projects);
        // Store phases in the map
        workspace.projects.forEach((project: any) => {
          console.log('[SessionsService] Processing project:', project.id);
          if (project.phases) {
            this.projectPhasesMap.set(project.id, project.phases);
            console.log(`[SessionsService] Stored ${project.phases.length} phases for project ${project.id}`);
          }
        });

        // Store projects without phases
        const basicProjects = workspace.projects.map(({ phases, ...project }: any) => project);
        this.projects.next(basicProjects);
        console.log('[SessionsService] Restored projects:', basicProjects.length);
      }

      if (workspace.sessions) {
        console.log('[SessionsService] Restoring sessions:', workspace.sessions);
        const validSessions = workspace.sessions.filter((session: any) => 
          session.id && session.title && session.status
        );
        this.sessions.next(validSessions);
        console.log(`[SessionsService] Restored ${validSessions.length} valid sessions`);
      }

      if (workspace.tabs) {
        console.log('[SessionsService] Restoring tabs:', workspace.tabs);
        const validTabs = workspace.tabs.filter((tab: any) => 
          tab.id && tab.sessionId && tab.title !== undefined
        );
        this.tabs.next(validTabs);
        console.log(`[SessionsService] Restored ${validTabs.length} valid tabs`);
      }

      if (workspace.openedTabs) {
        console.log('[SessionsService] Restoring opened tabs:', workspace.openedTabs);
        const validOpenedTabs = workspace.openedTabs.filter((tabId: string) => tabId);
        this.openedTabs.next(validOpenedTabs);
        console.log(`[SessionsService] Restored ${validOpenedTabs.length} valid opened tabs`);
      }

      if (workspace.selectedProject) {
        console.log('[SessionsService] Restoring selected project:', workspace.selectedProject);
        this.selectedProject.next(workspace.selectedProject);
        
        // Restore phases for selected project
        const phases = this.projectPhasesMap.get(workspace.selectedProject.id);
        if (phases) {
          this.phases.next(phases);
          console.log(`[SessionsService] Restored ${phases.length} phases for selected project`);
        }
      }

      if (workspace.selectedPhase) {
        console.log('[SessionsService] Restoring selected phase:', workspace.selectedPhase);
        this.selectedPhase.next(workspace.selectedPhase);
      }

      // Restore tab references
      const tabRefs = new Map<string, TabReference>();
      workspace.tabs?.forEach((tab: any) => {
        if (tab.reference && tab.sessionId) {
          tabRefs.set(tab.sessionId, tab.reference);
          console.log(`[SessionsService] Restored tab reference for session ${tab.sessionId}`);
        }
      });
      this.tabReferences.next(tabRefs);

      console.log('[SessionsService] Workspace state restoration completed successfully');
    } catch (error) {
      console.error('[SessionsService] Error during workspace state restoration:', error);
      // Initialize with empty state as fallback
      this.initializeEmptyState();
    }
  }

  private initializeEmptyState(): void {
    console.log('[SessionsService] Initializing empty state');
    this.projects.next([]);
    this.sessions.next([]);
    this.tabs.next([]);
    this.openedTabs.next([]);
    this.phases.next([]);
    this.selectedProject.next(null);
    this.selectedPhase.next(null);
    this.tabReferences.next(new Map());
  }

  async getPhases(projectColor: string): Promise<Phase[]> {
    console.log(`[SessionsService] Getting phases for project color ${projectColor}`);
    try {
      const project = this.projects.value.find(p => p.color === projectColor);
      if (project) {
        const phases = this.projectPhasesMap.get(project.id) || [];
        console.log(`[SessionsService] Retrieved ${phases.length} phases`);
        this.phases.next(phases);
        return phases;
      }
      return [];
    } catch (error) {
      console.error('[SessionsService] Error getting phases:', error);
      return [];
    }
  }

  private triggerSave(): void {
    if (this.initialized) {
      console.log('[SessionsService] Triggering workspace save');
      this.saveSubject.next();
    }
  }

  // Sessions Management
  async getSessions(userId: string, limit?: number, offset?: number): Promise<ChatSession[]> {
    console.log(`[SessionsService] Getting sessions for user ${userId}`, { limit, offset });
    let sessions = this.sessions.value;
    
    if (limit && offset) {
      sessions = sessions.slice(offset, offset + limit);
    } else if (limit) {
      sessions = sessions.slice(0, limit);
    }

    console.log(`[SessionsService] Returning ${sessions.length} sessions`);
    return sessions;
  }

  async getOpenSessions(userId: string): Promise<ChatSession[]> {
    console.log(`[SessionsService] Getting open sessions for user ${userId}`);
    const sessions = this.sessions.value.filter(s => s.status === SessionStatus.Active);
    console.log(`[SessionsService] Found ${sessions.length} open sessions`);
    return sessions;
  }

  async startSession(
    userId: string,
    initialMessage?: string,
    metadata?: Record<string, unknown>
  ): Promise<ChatSession> {
    console.log(`[SessionsService] Starting new session for user ${userId}`, { initialMessage, metadata });
    
    const currentProject = this.selectedProject.value;
    const currentPhase = this.selectedPhase.value;
    
    const newSession: ChatSession = {
      id: uuidv4(),
      title: initialMessage || (currentProject && currentPhase ? 
        `${currentProject.label} - ${currentPhase.label}` : 'New Chat'),
      status: SessionStatus.Active,
      createdAt: new Date(),
      updatedAt: new Date(),
      participants: {
        users: [userId],
        agents: []
      },
      metadata: {
        ...metadata,
        projectId: currentProject?.id,
        phaseId: currentPhase?.id
      }
    };

    const currentSessions = this.sessions.value;
    this.sessions.next([...currentSessions, newSession]);
    
    const openedTabs = this.openedTabs.value;
    this.openedTabs.next([...openedTabs, newSession.id]);
    
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => ({ ...tab, active: false }));
    updatedTabs.push({
      id: newSession.id,
      sessionId: newSession.id,
      title: newSession.title,
      active: true,
      type: 'chat'
    });
    this.tabs.next(updatedTabs);
    
    if (currentProject && currentPhase) {
      this.setTabReference(newSession.id, currentProject.id, currentPhase.id, currentProject.color);
    }
    
    console.log(`[SessionsService] Session ${newSession.id} created and activated`);
    this.triggerSave();
    return newSession;
  }

  private async saveWorkspace(): Promise<void> {
    if (!this.initialized) {
      console.log('[SessionsService] Skipping save - service not fully initialized');
      return;
    }

    console.log('[SessionsService] Beginning workspace save preparation');
    
    try {
      // Reconstruct projects with their phases
      const projectsWithPhases = this.projects.value.map(project => {
        const phases = this.projectPhasesMap.get(project.id) || [];
        console.log(`[SessionsService] Adding ${phases.length} phases to project ${project.id}`);
        return {
          ...project,
          phases
        };
      });

      // Build workspace state
      const workspaceState = {
        version: "1.0",
        lastUpdated: new Date().toISOString(),
        organization: "asky",
        workspace: {
          projects: projectsWithPhases,
          sessions: this.sessions.value,
          tabs: this.tabs.value,
          openedTabs: this.openedTabs.value,
          selectedProject: this.selectedProject.value,
          selectedPhase: this.selectedPhase.value
        }
      };

      console.log('[SessionsService] Workspace state prepared', {
        projectCount: projectsWithPhases.length,
        sessionCount: this.sessions.value.length,
        tabCount: this.tabs.value.length,
        openedTabCount: this.openedTabs.value.length
      });

      // Save to workspace service
      await this.workspaceService.saveWorkspace(workspaceState);
      console.log('[SessionsService] Workspace saved successfully');
    } catch (error) {
      console.error('[SessionsService] Error saving workspace:', error);
      throw new Error('Failed to save workspace state');
    }
  }

  async closeSession(sessionId: string, userId: string): Promise<void> {
    console.log(`[SessionsService] Closing session ${sessionId} for user ${userId}`);
    await this.updateSessionStatus(sessionId, SessionStatus.Completed, userId);
    
    const openedTabs = this.openedTabs.value;
    this.openedTabs.next(openedTabs.filter(id => id !== sessionId));
    
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.filter(tab => tab.sessionId !== sessionId);
    
    if (currentTabs.find(tab => tab.sessionId === sessionId)?.active && updatedTabs.length > 0) {
      updatedTabs[updatedTabs.length - 1].active = true;
    }
    
    this.tabs.next(updatedTabs);
    console.log(`[SessionsService] Session ${sessionId} closed and removed from tabs`);
    this.triggerSave();
  }

  async archiveSession(sessionId: string, userId: string): Promise<void> {
    console.log(`[SessionsService] Archiving session ${sessionId} for user ${userId}`);
    await this.updateSessionStatus(sessionId, SessionStatus.Archived, userId);
    this.triggerSave();
  }

  private async updateSessionStatus(
    sessionId: string,
    status: SessionStatus,
    userId: string
  ): Promise<void> {
    console.log(`[SessionsService] Updating session ${sessionId} status to ${status}`);
    const currentSessions = this.sessions.value;
    const sessionIndex = currentSessions.findIndex(s => s.id === sessionId);
    
    if (sessionIndex === -1) {
      console.warn(`[SessionsService] Session ${sessionId} not found`);
      return;
    }

    const updatedSessions = [...currentSessions];
    updatedSessions[sessionIndex] = {
      ...updatedSessions[sessionIndex],
      status,
      updatedAt: new Date()
    };

    this.sessions.next(updatedSessions);
    console.log(`[SessionsService] Session ${sessionId} status updated successfully`);
    this.triggerSave();
  }

  async updateSessionMetadata(
    sessionId: string,
    metadata: Record<string, unknown>,
    userId: string
  ): Promise<void> {
    console.log(`[SessionsService] Updating session ${sessionId} metadata for user ${userId}`, metadata);
    const currentSessions = this.sessions.value;
    const sessionIndex = currentSessions.findIndex(s => s.id === sessionId);
    
    if (sessionIndex === -1) {
      console.warn(`[SessionsService] Session ${sessionId} not found for metadata update`);
      return;
    }

    const updatedSessions = [...currentSessions];
    updatedSessions[sessionIndex] = {
      ...updatedSessions[sessionIndex],
      metadata: { ...updatedSessions[sessionIndex].metadata, ...metadata },
      updatedAt: new Date()
    };

    this.sessions.next(updatedSessions);
    console.log(`[SessionsService] Session ${sessionId} metadata updated successfully`);
    this.triggerSave();
  }

  // Tabs Management
  openTab(tab: { id: string; title: string; type?: string }): void {
    console.log(`[SessionsService] Opening tab`, tab);
    const openedTabs = this.openedTabs.value;
    if (!openedTabs.includes(tab.id)) {
      this.openedTabs.next([...openedTabs, tab.id]);
    }

    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(t => ({ ...t, active: false }));
    const existingTabIndex = updatedTabs.findIndex(t => t.sessionId === tab.id);

    if (existingTabIndex !== -1) {
      updatedTabs[existingTabIndex].active = true;
    } else {
      updatedTabs.push({
        id: tab.id,
        sessionId: tab.id,
        title: tab.title,
        active: true,
        type: tab.type || 'chat'
      });
    }

    this.tabs.next(updatedTabs);
    console.log(`[SessionsService] Tab ${tab.id} opened and activated`);
    this.triggerSave();
  }

  setActiveSession(sessionId: string): void {
    console.log(`[SessionsService] Setting active session to ${sessionId}`);
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => ({
      ...tab,
      active: tab.sessionId === sessionId
    }));
    this.tabs.next(updatedTabs);
    console.log(`[SessionsService] Session ${sessionId} set as active`);
    this.triggerSave();
  }

  // Projects and Phases Management
  async loadInitialData(): Promise<void> {
    console.log('[SessionsService] Loading initial project data');
    try {
      const mockProjects = await this.workspaceService.loadWorkspace();
      if (mockProjects) {
        console.log('[SessionsService] Projects loaded successfully');
        this.projects.next(mockProjects.workspace.projects);
      }
    } catch (error) {
      console.error('[SessionsService] Error loading initial data:', error);
      this.projects.next([]);
    }
  }

  async selectProject(project: Project): Promise<void> {
    console.log(`[SessionsService] Selecting project`, project);
    this.selectedProject.next(project);
    try {
      const phases = await this.getPhases(project.color);
      this.selectedPhase.next(null);
      console.log(`[SessionsService] Project ${project.id} selected and phases loaded`);
    } catch (error) {
      console.error('[SessionsService] Error selecting project:', error);
    }
    this.triggerSave();
  }

  selectPhase(phase: Phase): void {
    console.log(`[SessionsService] Selecting phase`, phase);
    this.selectedPhase.next(phase);
    console.log(`[SessionsService] Phase ${phase.id} selected`);
    
    // Update chat service with both phase ID and label
    const currentProject = this.selectedProject.value;
    this.chatService.updateContextReferences(
      currentProject?.id,
      phase.id,
      phase.label
    );
    
    this.triggerSave();
  }

  // Project management methods
  addProject(): void {
    console.log('Adding new project...');
    
    // Create new project
    const newProject: Project = {
      id: uuidv4(),
      label: 'Process artifact',
      color: '#4A90E2', // A nice blue color
      type: 'artifact'
    };

    // Create phases
    const phases: Phase[] = [
      { id: uuidv4(), label: 'Define artifact type', projectId: newProject.id },
      { id: uuidv4(), label: 'Upload artifact', projectId: newProject.id },
      { id: uuidv4(), label: 'Describe artifact', projectId: newProject.id },
      { id: uuidv4(), label: 'Query related artifacts', projectId: newProject.id }
    ];

    // Update projects and phases
    const currentProjects = this.projects.getValue();
    const currentPhases = this.phases.getValue();
    
    this.projects.next([...currentProjects, newProject]);
    this.phases.next([...currentPhases, ...phases]);
    
    // Update project phases map
    this.projectPhasesMap.set(newProject.id, phases);

    // Save workspace
    this.saveWorkspace();

    // Select the new project and its first phase
    this.selectProject(newProject);
    this.selectPhase(phases[0]);
  }

  // Tab References
  setTabReference(sessionId: string, projectId: string, phaseId: string, color: string): void {
    console.log(`[SessionsService] Setting tab reference for session ${sessionId}`);
    const refs = this.tabReferences.value;
    refs.set(sessionId, { projectId, phaseId, color });
    this.tabReferences.next(refs);

    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => {
      if (tab.sessionId === sessionId) {
        const project = this.projects.value.find(p => p.id === projectId);
        const phase = this.phases.value.find(p => p.id === phaseId);
        return {
          ...tab,
          title: phase ? `${project?.label} - ${phase.label}` : project?.label || tab.title,
          reference: { projectId, phaseId, color }
        };
      }
      return tab;
    });
    this.tabs.next(updatedTabs);
    console.log(`[SessionsService] Tab reference set for session ${sessionId}`);
    this.triggerSave();
  }

  // Observables
  getOpenedTabs(): Observable<string[]> {
    return this.openedTabs.asObservable();
  }

  getTabs(): Observable<TabInfo[]> {
    return this.tabs.asObservable();
  }

  getProjects(): Observable<Project[]> {
    return this.projects.asObservable();
  }

  getSelectedProject(): Observable<Project | null> {
    return this.selectedProject.asObservable();
  }

  getSelectedPhase(): Observable<Phase | null> {
    return this.selectedPhase.asObservable();
  }

  ngOnDestroy(): void {
    console.log('[SessionsService] Service being destroyed, saving final state');
    this.saveWorkspace().catch(error => {
      console.error('[SessionsService] Error during final save:', error);
    });
  }

  async updateSessionTitleOnFirstMessage(sessionId: string, message: string): Promise<void> {
    console.log(`[SessionsService] Updating session title for first message, sessionId: ${sessionId}`);
    const currentSessions = this.sessions.value;
    const session = currentSessions.find(s => s.id === sessionId);
    if (!session) {
      console.warn(`[SessionsService] Session ${sessionId} not found for title update`);
      return;
    }

    const title = message.length > 50 ? message.substring(0, 47) + '...' : message;
    
    const updatedSessions = currentSessions.map(s => {
      if (s.id === sessionId) {
        return { ...s, title };
      }
      return s;
    });
    this.sessions.next(updatedSessions);

    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => {
      if (tab.sessionId === sessionId) {
        return { ...tab, title };
      }
      return tab;
    });
    this.tabs.next(updatedTabs);

    console.log(`[SessionsService] Session ${sessionId} title updated to: ${title}`);
    this.triggerSave();
  }

  async updateSessionProjectAndPhase(
    sessionId: string, 
    project: Project | null, 
    phase: Phase | null, 
    skipHeaderUpdate: boolean = false
  ): Promise<boolean> {
    console.log('[SessionsService] Updating session project and phase', {
      sessionId,
      projectId: project?.id,
      phaseId: phase?.id,
      skipHeaderUpdate
    });

    const session = this.sessions.value.find(s => s.id === sessionId);
    if (!session || session.status !== SessionStatus.Active) {
      console.warn('[SessionsService] Cannot update session: Session not found or not active');
      return false;
    }

    const hasMessages = await this.chatService.hasMessages(sessionId);
    if (hasMessages) {
      console.warn('[SessionsService] Cannot update session: Session has messages');
      return false;
    }

    if (this.isSessionMatchingProjectAndPhase(session, project, phase)) {
      console.log('[SessionsService] Session already matches selected project and phase');
      return true;
    }

    const currentSessions = this.sessions.value;
    const updatedSessions = currentSessions.map(s => {
      if (s.id === sessionId) {
        return {
          ...s,
          title: project && phase ? `${project.label} - ${phase.label}` : s.title,
          metadata: {
            ...s.metadata,
            projectId: project?.id,
            phaseId: phase?.id
          }
        };
      }
      return s;
    });
    this.sessions.next(updatedSessions);

    if (project) {
      this.setTabReference(
        sessionId, 
        project.id, 
        phase?.id || '', 
        project.color
      );
    }

    if (!skipHeaderUpdate) {
      if (project) {
        this.selectedProject.next(project);
        const phases = await this.getPhases(project.color);
        if (phase && phases.find(p => p.id === phase.id)) {
          this.selectedPhase.next(phase);
        } else {
          this.selectedPhase.next(null);
        }
      } else {
        this.selectedProject.next(null);
        this.selectedPhase.next(null);
      }
    }

    console.log(`[SessionsService] Session ${sessionId} project and phase updated successfully`);
    this.triggerSave();
    return true;
  }

  private isSessionMatchingProjectAndPhase(
    session: ChatSession,
    project: Project | null,
    phase: Phase | null
  ): boolean {
    const metadata = session.metadata as Record<string, unknown>;
    return metadata['projectId'] === project?.id && metadata['phaseId'] === phase?.id;
  }

  async startSessionWithProject(
    userId: string,
    project: Project,
    phase: Phase | null = null,
    initialMessage?: string
  ): Promise<ChatSession> {
    console.log(`[SessionsService] Starting new session with project for user ${userId}`, {
      project,
      phase,
      initialMessage
    });
    
    const newSession: ChatSession = {
      id: uuidv4(),
      title: phase ? `${project.label} - ${phase.label}` : project.label,
      status: SessionStatus.Active,
      createdAt: new Date(),
      updatedAt: new Date(),
      participants: {
        users: [userId],
        agents: []
      },
      metadata: {
        projectId: project.id,
        phaseId: phase?.id
      }
    };

    const currentSessions = this.sessions.value;
    this.sessions.next([...currentSessions, newSession]);

    const openedTabs = this.openedTabs.value;
    this.openedTabs.next([...openedTabs, newSession.id]);
    
    const currentTabs = this.tabs.value;
    const updatedTabs = currentTabs.map(tab => ({ ...tab, active: false }));
    updatedTabs.push({
      id: newSession.id,
      sessionId: newSession.id,
      title: newSession.title,
      active: true,
      type: 'chat'
    });
    this.tabs.next(updatedTabs);
    
    this.setTabReference(newSession.id, project.id, phase?.id || '', project.color);
    
    console.log(`[SessionsService] New session created with project: ${newSession.id}`);
    this.triggerSave();
    return newSession;
  }

  getTabReference(sessionId: string): TabReference | undefined {
    return this.tabReferences.value.get(sessionId);
  }

  async addParticipant(
    sessionId: string,
    participantId: string,
    role: 'user' | 'agent',
    userId: string
  ): Promise<void> {
    console.log(`[SessionsService] Adding participant ${participantId} as ${role} to session ${sessionId}`);
    const currentSessions = this.sessions.value;
    const sessionIndex = currentSessions.findIndex(s => s.id === sessionId);
    
    if (sessionIndex === -1) {
      console.warn(`[SessionsService] Session ${sessionId} not found for adding participant`);
      return;
    }

    const updatedSessions = [...currentSessions];
    const session = { ...updatedSessions[sessionIndex] };
    
    if (role === 'user') {
      session.participants.users = [...session.participants.users, participantId];
    } else {
      session.participants.agents = [...session.participants.agents, participantId];
    }

    updatedSessions[sessionIndex] = session;
    this.sessions.next(updatedSessions);
    
    console.log(`[SessionsService] Participant ${participantId} added to session ${sessionId}`);
    this.triggerSave();
  }

  async removeParticipant(
    sessionId: string,
    participantId: string,
    userId: string
  ): Promise<void> {
    console.log(`[SessionsService] Removing participant ${participantId} from session ${sessionId}`);
    const currentSessions = this.sessions.value;
    const sessionIndex = currentSessions.findIndex(s => s.id === sessionId);
    
    if (sessionIndex === -1) {
      console.warn(`[SessionsService] Session ${sessionId} not found for removing participant`);
      return;
    }

    const updatedSessions = [...currentSessions];
    const session = { ...updatedSessions[sessionIndex] };
    
    session.participants.users = session.participants.users.filter(id => id !== participantId);
    session.participants.agents = session.participants.agents.filter(id => id !== participantId);

    updatedSessions[sessionIndex] = session;
    this.sessions.next(updatedSessions);
    
    console.log(`[SessionsService] Participant ${participantId} removed from session ${sessionId}`);
    this.triggerSave();
  }
}