<aside class="sidebar" 
       [class.docked]="isDocked"
       [class.visible]="isVisible || isDocked"
       [class.left]="config.position === 'left'"
       [class.right]="config.position === 'right'"
       [style.width.px]="width"
       (mouseenter)="onSidebarMouseEnter()"
       (mouseleave)="onSidebarMouseLeave()"
       >


  <div class="sidebar-content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>

  <div class="resize-handle">
       <button class="dock-button" (click)="toggleDock()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256">
          <path [attr.d]="isDocked ? 
            'M232,128a8,8,0,0,1-8,8H91.31l58.35,58.34a8,8,0,0,1-11.32,11.32l-72-72a8,8,0,0,1,0-11.32l72-72a8,8,0,0,1,11.32,11.32L91.31,120H224A8,8,0,0,1,232,128ZM40,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,40,32Z' : 
            'M189.66,122.34a8,8,0,0,1,0,11.32l-72,72a8,8,0,0,1-11.32-11.32L164.69,136H32a8,8,0,0,1,0-16H164.69L106.34,61.66a8,8,0,0,1,11.32-11.32ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z'">
          </path>
        </svg>
      </button>
  </div>
</aside>