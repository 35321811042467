// src/app/home/home.component.ts
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from '../components/layout/header/header.component';
import { JamBarComponent } from '../components/layout/jam-bar/jam-bar.component';
import { TabContainerComponent } from '../components/tabs/tab-container/tab-container.component';
import { SidebarComponent } from '../components/layout/sidebar/sidebar.component';
import { PropertiesBarComponent } from '../components/layout/properties-bar/properties-bar.component';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { SessionsService } from '../services/sessions.service';
import { PropertiesBarService } from '../services/properties-bar.service';
import { Subscription } from 'rxjs';

interface SidebarState {
  isDocked: boolean;
  isExpanded: boolean;
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    HeaderComponent,
    JamBarComponent,
    TabContainerComponent,
    SidebarComponent,
    PropertiesBarComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  @ViewChild(PropertiesBarComponent) propertiesBar!: PropertiesBarComponent;

  constructor(
    private authService: MsalService,
    private router: Router,
    private sessionsService: SessionsService,
    private propertiesBarService: PropertiesBarService
  ) {
    this.subscription = this.propertiesBarService.state$.subscribe(state => {
      this.propertiesBarState = {
        isDocked: state.isDocked,
        isExpanded: state.isExpanded
      };
      
      // Ensure the properties bar component is updated
      if (this.propertiesBar?.sidebar) {
        this.propertiesBar.sidebar.isDocked = state.isDocked;
      }
    });
  }

  propertiesBarState: SidebarState = {
    isDocked: false,
    isExpanded: false
  };

  ngOnInit() {}

  ngAfterViewInit() {
    // Initialize properties bar state
    if (this.propertiesBar?.sidebar) {
      this.propertiesBar.sidebar.isDocked = this.propertiesBarState.isDocked;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: location.href.indexOf("localhost") > -1 ? 
        'http://localhost:4200' : 'https://clinic.aisel.co'
    });
  }

  onSidebarStateChange(isCompact: boolean) {
    console.log('Sidebar state changed:', isCompact);
  }

  onPropertiesBarStateChange(isDocked: boolean) {
    // Update both the local state and the service
    this.propertiesBarState.isDocked = isDocked;
    this.propertiesBarService.setState({ isDocked });
  }
}
