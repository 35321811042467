// src/app/models/session.interface.ts
export interface Session {
  id: string;
  title: string;
  messages: ChatMessage[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ChatMessage {
  id: string;
  content: string;
  sender: 'user' | 'assistant';
  timestamp: Date;
}

export interface Project {
  id: string;
  label: string;
  color: string;
  type: string;
}

export interface Phase {
  id: string;
  label: string;
  projectId: string;
}

export interface TabReference {
  projectId: string;
  phaseId: string;
  color: string;
}

export interface TabInfo {
  id: string;
  sessionId: string;
  title: string;
  active: boolean;
  reference?: TabReference;
  type: string;
}