<!-- src/app/components/session/chat/chat.component.html -->
<div class="chat-container">
  <ng-container *ngIf="!isLoading">
    <div class="selection-container" *ngIf="!hasMessages">
      <div class="selection-group">
        Project
        <mat-form-field appearance="outline">
          <mat-select [value]="selectedProjectId" (selectionChange)="onProjectChange($event)">
            <mat-option *ngFor="let project of projects$ | async" [value]="project.id">
              {{project.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;&nbsp;&nbsp;Phase
        <mat-form-field appearance="outline">
          <mat-select [value]="selectedPhaseId" (selectionChange)="onPhaseChange($event)">
            <mat-option *ngFor="let phase of phases$ | async" [value]="phase.id">
              {{phase.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="chat-content">
      <div class="messages-container" #messagesContainer>
        <ng-container *ngFor="let message of messages">
          <app-message 
            [message]="message"
            (edit)="onEditMessage(message)"
            (copy)="onCopyMessage(message)"
            (read)="onReadMessage(message)"
            (like)="onLikeMessage(message)"
            (dislike)="onDislikeMessage(message)"
            (enlargeArtifact)="onEnlargeArtifact($event)">
          </app-message>
        </ng-container>
      </div>
      <div class="recommended-flows" *ngIf="messages.length === 0">
        <div class="flows-list">
          <ng-container *ngIf="(recommendedFlows$ | async) as flows">
            <a *ngFor="let flow of flows" class="flow-item" (click)="onFlowSelect(flow)">
              <i class="material-icons flow-icon">arrow_forward</i>
              <span class="flow-text">{{ flow.name }}</span>
            </a>
            <div *ngIf="flows.length === 0" class="no-flows">
              No recommended flows available
            </div>
          </ng-container>
        </div>
      </div>
      <app-input-box
        [isProcessing]="(isProcessing$ | async) ?? false"
        (messageSent)="onMessageSent($event)">
      </app-input-box>
    </div>
  </ng-container>
</div>