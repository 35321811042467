// src/app/components/session/message/message.component.ts
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMessage, Artifact } from '../../../models/chat.models';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

interface ProcessedPart {
  content: string;
  artifactTitle?: string;
  remainingContent?: string;
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule]
})
export class MessageComponent implements OnInit {
  @Input() message!: ChatMessage;
  @Output() edit = new EventEmitter<void>();
  @Output() copy = new EventEmitter<void>();
  @Output() read = new EventEmitter<void>();
  @Output() like = new EventEmitter<void>();
  @Output() dislike = new EventEmitter<void>();
  @Output() enlargeArtifact = new EventEmitter<Artifact>();
  
  isHovered = false;
  processedParts: ProcessedPart[] = [];
  artifactPreviews: { [key: string]: string } = {};

  ngOnInit() {
    this.processMessageContent();
  }

  private processMessageContent() {
    if (!this.message.artifacts?.length) {
      this.processedParts = [{ content: this.message.content }];
      return;
    }

    let content = this.message.content;
    this.message.artifacts.forEach(artifact => {
      const preview = artifact.content.split('\n').slice(0, 2).join('\n');
      this.artifactPreviews[artifact.title] = preview;
    });

    // Split content by artifact placeholders
    const parts = content.split(/\[\[(.*?)\]\]/);
    this.processedParts = parts.map((part, index) => {
      if (index % 2 === 0) {
        return { content: part };
      } else {
        return {
          content: '',
          artifactTitle: part,
          remainingContent: parts[index + 1]
        };
      }
    });
  }

  getArtifactByTitle(title: string): Artifact | undefined {
    return this.message.artifacts?.find(a => a.title === title);
  }

  onEnlargeClick(artifact: Artifact) {
    console.log('Enlarge artifact clicked:', artifact);
    this.enlargeArtifact.emit(artifact);
  }

  getArtifactPreview(title: string): string {
    return this.artifactPreviews[title] || '';
  }

  shouldShowArtifactBelow(artifact: Artifact): boolean {
    return !this.message.content.includes(`[[${artifact.title}]]`);
  }

  getUnplacedArtifacts(): Artifact[] {
    if (!this.message.artifacts) return [];
    return this.message.artifacts.filter(artifact => 
      !this.message.content.includes(`[[${artifact.title}]]`)
    );
  }

  onCopy(): void {
    if (this.message.content) {
      navigator.clipboard.writeText(this.message.content);
      this.copy.emit();
    }
  }

  onRead(): void {
    if (this.message.content) {
      const utterance = new SpeechSynthesisUtterance(this.message.content);
      window.speechSynthesis.speak(utterance);
      this.read.emit();
    }
  }

  onEdit(): void {
    this.edit.emit();
  }

  onLike(): void {
    this.like.emit();
  }

  onDislike(): void {
    this.dislike.emit();
  }
}