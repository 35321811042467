import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { Project, Phase, TabInfo } from '../models/session.interface';
import { ChatSession, ChatMessage } from '../models/chat.models';

interface WorkspaceBlob {
  version: string;
  lastUpdated: string;
  organization: string;
  workspace: {
    projects: Project[];
    sessions: ChatSession[];
    tabs: TabInfo[];
    openedTabs: string[];
    selectedProject?: Project | null;
    selectedPhase?: Phase | null;
  }
}

interface WorkspaceArtifact {
  id: string;
  artifactcontainerreference: string;
  displayname: string;
  description: string;
  content: {
    blob: string;
    organizationId: string;
    agentId: string;
    lastaccess: number;
  }
}

interface WorkspaceResponse {
    Items: Array<{
      id: string;
      content: {
        blob: string;
        agentId: string;
        lastaccess: number;
      };
    }>;
  }

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private readonly containerRef = environment.workspaceContainerRef;
  private readonly artifactsApiUrl = environment.artifactsApiUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    console.log('[WorkspaceService] Initializing service');
  }

  async loadWorkspace(): Promise<any> {
    try {
      const user = await this.userService.getCurrentUser();
      if (!user?.email) {
        console.warn('[WorkspaceService] No user email available');
        return null;
      }

      console.log('[WorkspaceService] Loading workspace for user:', user.email);
      
      const queryBody = {
        artifactcontainerreference: this.containerRef,
        partitionKey: user.email,
        query: '',
        pageSize: 1
      };

      const response = await firstValueFrom(
        this.http.post<WorkspaceResponse>(`${this.artifactsApiUrl}/artifacts/query${environment.keyCode}`, queryBody)
      );

      console.log('[WorkspaceService] Received response:', response);

      if (!response.Items?.length) {
        console.log('[WorkspaceService] No existing workspace found for user');
        return null;
      }

      const workspaceData = response.Items[0];
      console.log('[WorkspaceService] Processing workspace data with ID:', workspaceData.id);

      try {
        const parsedBlob = JSON.parse(workspaceData.content.blob);
        console.log('[WorkspaceService] Successfully parsed workspace blob:', parsedBlob);
        return parsedBlob;
      } catch (parseError) {
        console.error('[WorkspaceService] Error parsing workspace blob:', parseError);
        return null;
      }
    } catch (error) {
      console.error('[WorkspaceService] Error loading workspace:', error);
      return null;
    }
  }

  async saveWorkspace(workspace: WorkspaceBlob): Promise<void> {
    try {
      const user = await this.userService.getCurrentUser();
      if (!user?.email) {
        console.warn('[WorkspaceService] No user email available for save');
        return;
      }

      console.log('[WorkspaceService] Saving workspace for user:', user.email);

      const artifact = {
        id: `workspace-${user.email}`,
        artifactcontainerreference: this.containerRef,
        displayname: `Workspace for ${user.email}`,
        description: 'User workspace data',
        content: {
          blob: JSON.stringify(workspace),
          organizationId: environment.organizationId,
          agentId: user.email,
          lastaccess: Date.now()
        }
      };

      await firstValueFrom(
        this.http.post(`${this.artifactsApiUrl}/artifacts`, artifact)
      );

      console.log('[WorkspaceService] Workspace saved successfully');
    } catch (error) {
      console.error('[WorkspaceService] Error saving workspace:', error);
      throw error;
    }
  }
}