import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChatMessage, ChatSession, Artifact, MessageRole, SessionStatus, ArtifactAction } from '../models/chat.models';
import { RecommendedFlow } from '../models/recommended-flow';
import { RecommendedFlowsService } from './recommended-flows.service';
import { v4 as uuidv4 } from 'uuid';

// Update enum to match C# API
enum ClaudeModel {
  Sonnet = 0,
  Haiku = 1
}

interface BedrockRequest {
  prompt: string;
  model: ClaudeModel;  // Changed from string to enum
  maxTokens: number;
  temperature: number;
  topP: number;
  topK: number;
}

interface BedrockResponse {
  content: string;
}

interface SessionMessageBase {
  id: string;
  sessionidId: string;
  content: string;
  role: string;
  senderId: string;
  timestamp: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private readonly sessionManagerApiUrl = `${environment.artifactsApiUrl}/session`;
  private readonly orgId = 'asky';
  private messagesSubject = new BehaviorSubject<ChatMessage[]>([]);
  private artifactsSubject = new BehaviorSubject<Artifact[]>([]);
  recommendedFlows$ = this.recommendedFlowsService.recommendedFlows$;
  private messageHandlers: ((message: ChatMessage) => void)[] = [];

  constructor(
    private http: HttpClient,
    private recommendedFlowsService: RecommendedFlowsService
  ) {
    console.log('[ChatService] Initializing service');
  }

  onMessage(handler: (message: ChatMessage) => void): () => void {
    console.log('[ChatService] Registering message handler');
    this.messageHandlers.push(handler);
    return () => {
      const index = this.messageHandlers.indexOf(handler);
      if (index > -1) {
        this.messageHandlers.splice(index, 1);
        console.log('[ChatService] Unregistered message handler');
      }
    };
  }

  private notifyMessageHandlers(message: ChatMessage): void {
    console.log('[ChatService] Notifying message handlers', { messageId: message.id });
    this.messageHandlers.forEach(handler => handler(message));
  }

  private parseMessageContent(content: string, messageId: string, sessionId: string, senderId: string, timestamp: Date): ChatMessage {
    if (typeof content !== 'string') {
      return {
        id: messageId,
        sessionId,
        content: String(content),
        role: MessageRole.Agent,
        senderId,
        timestamp,
        artifacts: []
      };
    }

    const trimmedContent = content.trim();
    if (trimmedContent.startsWith('{')) {
      try {
        const parsedContent = JSON.parse(trimmedContent);
        if (parsedContent && typeof parsedContent.agentanswer === 'string') {
          const artifacts: Artifact[] = [];
          
          if (parsedContent.mingusscript && parsedContent.mingusscript.name && parsedContent.mingusscript.script) {
            artifacts.push({
              id: uuidv4(),
              title: parsedContent.mingusscript.name,
              type: 'mingusscript',
              content: parsedContent.mingusscript.script,
              action: ArtifactAction.Created,
              createdAt: new Date(),
              language: 'mingus'
            });
          }

          return {
            id: messageId,
            sessionId,
            content: parsedContent.agentanswer,
            role: MessageRole.Agent,
            senderId,
            timestamp,
            artifacts
          };
        }
      } catch (parseError) {
        console.warn('[ChatService] Failed to parse message content as JSON:', parseError);
      }
    }

    return {
      id: messageId,
      sessionId,
      content,
      role: MessageRole.Agent,
      senderId,
      timestamp,
      artifacts: []
    };
  }

  async getMessages(
    sessionId: string,
    userId: string,
    limit?: number,
    before?: Date
  ): Promise<ChatMessage[]> {
    console.log(`[ChatService] Getting messages for session ${sessionId}`, { userId, limit, before });
    
    try {
      const response = await firstValueFrom(
        this.http.post<SessionMessageBase[]>(`${this.sessionManagerApiUrl}/messages${environment.keyCode}`, {
          sessionId,
          orgId: this.orgId
        })
      );

      const messages: ChatMessage[] = response.map(msg => {
        const parsedMessage = this.parseMessageContent(msg.content, msg.id, msg.sessionidId, msg.senderId, new Date(msg.timestamp));
        return {
          ...parsedMessage,
          role: msg.role as MessageRole
        };
      });

      this.messagesSubject.next(messages);
      console.log(`[ChatService] Found ${messages.length} messages`);
      return messages;
    } catch (error) {
      console.error('[ChatService] Error fetching messages:', error);
      throw error;
    }
  }

  async hasMessages(sessionId: string): Promise<boolean> {
    console.log('[ChatService] Checking for messages in session', { sessionId });
    const messages = await this.getMessages(sessionId, 'default-user', 1);
    const hasMessages = messages.length > 0;
    console.log('[ChatService] Session has messages:', hasMessages);
    return hasMessages;
  }

  async sendMessage(
    sessionId: string,
    content: string,
    userId: string,
    artifacts?: Artifact[]
  ): Promise<ChatMessage> {
    console.log(`[ChatService] Sending message in session ${sessionId}`, { 
      contentLength: content.length,
      userId,
      artifactsCount: artifacts?.length 
    });
    
    const userMessage: ChatMessage = {
      id: uuidv4(),
      sessionId,
      content,
      role: MessageRole.User,
      senderId: userId,
      timestamp: new Date(),
      artifacts: artifacts || [],
    };

    // Add message to the list immediately
    const currentMessages = this.messagesSubject.value;
    this.messagesSubject.next([...currentMessages, userMessage]);
    this.notifyMessageHandlers(userMessage);

    try {
      // Send message to session manager API
      const messageRequest = {
        sessionId,
        orgId: this.orgId,
        message: {
          id: userMessage.id,
          content: userMessage.content,
          senderId: userMessage.senderId,
          role: userMessage.role,
          timestamp: userMessage.timestamp,
          sessionidId: userMessage.sessionId,
          sectionidId: null
        }
      };

      const response = await firstValueFrom(
        this.http.post<SessionMessageBase>(`${this.sessionManagerApiUrl}/message${environment.keyCode}`, messageRequest)
      );

      // Convert API response to ChatMessage
      if (response) {
        const agentMessage: ChatMessage = this.parseMessageContent(response.content, response.id, response.sessionidId, response.senderId, new Date(response.timestamp));

        // Add agent message to state
        const messages = this.messagesSubject.value;
        this.messagesSubject.next([...messages, agentMessage]);
        console.log('[ChatService] Agent message added to state', { messageId: agentMessage.id });
        this.notifyMessageHandlers(agentMessage);
      }

      return userMessage;
    } catch (error) {
      console.error('[ChatService] Error processing message:', error);
      
      // Send error message to user
      const errorMessage: ChatMessage = {
        id: uuidv4(),
        sessionId,
        content: 'Sorry, I encountered an error processing your message. Please try again.',
        role: MessageRole.Agent,
        senderId: 'system',
        timestamp: new Date(),
        artifacts: [],
      };

      const messages = this.messagesSubject.value;
      this.messagesSubject.next([...messages, errorMessage]);
      console.log('[ChatService] Error message added to state', { messageId: errorMessage.id });
      this.notifyMessageHandlers(errorMessage);

      throw error;
    }
  }

  updateContextReferences(projectId?: string, phaseId?: string, phaseLabel?: string): void {
    console.log('[ChatService] Updating context references:', { projectId, phaseId, phaseLabel });
    this.recommendedFlowsService.updateFlows(projectId?.toLowerCase(), phaseLabel?.toLowerCase());
  }
}