import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RecordManagementService } from '../../../services/record-management.service';
import { RecordsGroupComponent } from '../records-group/records-group.component';
import { RecordGroupList, RecordsGroup } from '../../../models/record-management.interface';
import { Subscription } from 'rxjs';

interface Breadcrumb {
  path: string;
  label: string;
}

@Component({
  selector: 'app-start-page',
  standalone: true,
  imports: [CommonModule, RecordsGroupComponent, MatIconModule, MatButtonModule],
  template: `
    <div class="start-page-container">
      <div class="navigation-bar">
        <button mat-icon-button 
                class="back-button" 
                *ngIf="currentPath !== 'path_/'"
                (click)="navigateUp()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="breadcrumbs">
          <span *ngFor="let crumb of breadcrumbs; let last = last">
            <span class="breadcrumb-item" 
                  [class.clickable]="!last"
                  (click)="!last ? navigateToPath(crumb.path) : null">
              {{crumb.label}}
            </span>
            <span *ngIf="!last" class="separator">/</span>
          </span>
        </div>
      </div>
      <div class="content-container" *ngIf="currentContent">
        <div class="folders-container" *ngIf="getFolders().length > 0">
          <app-records-group
            *ngFor="let group of getFolders()"
            [group]="group"
            (onPinToggle)="onPinToggle($event)"
            (onExpandToggle)="onExpandToggle($event)"
            (onFolderClick)="onFolderClick($event)">
          </app-records-group>
        </div>
        <div class="records-container" *ngIf="getRecords().length > 0">
          <app-records-group
            *ngFor="let group of getRecords()"
            [group]="group"
            (onExpandToggle)="onExpandToggle($event)">
          </app-records-group>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .start-page-container {
      display: flex;
      flex-direction: column;
      padding: 24px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    }

    .navigation-bar {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
      padding: 8px;
      background: var(--surface-card);
      border-radius: 4px;
    }

    .breadcrumbs {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      color: var(--text-color);
    }

    .breadcrumb-item {
      &.clickable {
        cursor: pointer;
        color: var(--primary-color);
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .separator {
      color: var(--text-color-secondary);
    }

    .content-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      background: var(--surface-card);
      border-radius: 8px;
      padding: 24px;
    }

    .folders-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 16px;
      border-bottom: 1px solid var(--surface-border);
      padding-bottom: 24px;
    }

    .records-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 16px;
    }

    :host ::ng-deep {
      .session-item.is-folder {
        cursor: pointer;
        &:hover {
          background: var(--surface-hover);
        }
      }
    }
  `]
})
export class StartPageComponent implements OnInit, OnDestroy {
  currentPath: string = '';
  currentContent: RecordGroupList | null = null;
  breadcrumbs: Breadcrumb[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private recordManagementService: RecordManagementService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.recordManagementService.getCurrentPath().subscribe(path => {
        this.currentPath = path;
        this.updateBreadcrumbs();
      }),
      this.recordManagementService.getCurrentContent().subscribe(content => {
        this.currentContent = content;
      })
    );

    // Load initial data
    this.recordManagementService.navigateToFolder('path_/');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getFolders(): RecordsGroup[] {
    return this.currentContent?.groups.filter(g => g.type === 'folder') || [];
  }

  getRecords(): RecordsGroup[] {
    return this.currentContent?.groups.filter(g => g.type === 'record') || [];
  }

  private updateBreadcrumbs() {
    const parts = this.currentPath.replace('path_/', '').split('/').filter(p => p);
    this.breadcrumbs = [
      { path: 'path_/', label: 'Root' },
      ...parts.map((part, index) => ({
        path: 'path_/' + parts.slice(0, index + 1).join('/') + '/',
        label: part.replace('path_', '')
      }))
    ];
  }

  navigateUp() {
    if (this.breadcrumbs.length > 1) {
      const parentPath = this.breadcrumbs[this.breadcrumbs.length - 2].path;
      this.navigateToPath(parentPath);
    }
  }

  navigateToPath(path: string) {
    this.recordManagementService.navigateToFolder(path);
  }

  onPinToggle(groupId: string) {
    this.recordManagementService.toggleGroupPin(groupId);
  }

  onExpandToggle(groupId: string) {
    this.recordManagementService.toggleGroupExpansion(groupId);
  }

  async onFolderClick(group: RecordsGroup) {
    const hasChildren = await this.hasChildren(group);
    if (hasChildren) {
      this.recordManagementService.navigateToFolder(group.id);
    }
  }

  private async hasChildren(group: RecordsGroup): Promise<boolean> {
    if (group.type !== 'folder') {
      return false;
    }
    return await this.recordManagementService.hasFolderChildren(group.id);
  }
}
