<div class="app-container">
  <app-header class="app-header"></app-header>
  
  <div class="main-container">
    <app-sidebar class="app-sidebar"
                 (stateChange)="onSidebarStateChange($event)">
    </app-sidebar>
    <main class="main-content">
      <app-tab-container></app-tab-container>
    </main>
    <app-properties-bar (stateChange)="onPropertiesBarStateChange($event)">
    </app-properties-bar>
  </div>
</div>
