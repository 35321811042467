import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TabComponent } from '../tab/tab.component';
import { Project, Phase } from '../../../models/session.interface';

@Component({
  selector: 'app-tabs-list',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule,
    TabComponent
  ],
  templateUrl: './tabs-list.component.html',
  styleUrls: ['./tabs-list.component.scss']
})
export class TabsListComponent {
  @Input() tabs: (Project | Phase)[] = [];
  @Input() selectedTab: Project | Phase | null = null;
  @Input() compact: boolean = false;
  @Input() projectColor?: string;
  @Output() tabSelected = new EventEmitter<Project | Phase>();
  @Output() addProjectClicked = new EventEmitter<void>();

  private readonly defaultColor = '#5270ff';

  selectTab(tab: Project | Phase) {
    this.tabSelected.emit(tab);
  }

  isSelected(tab: Project | Phase): boolean {
    return this.selectedTab?.id === tab.id;
  }

  getColor(tab: Project | Phase): string {
    if (this.isProject(tab)) {
      return tab.color;
    }
    // For phases, use the project color if available
    return this.projectColor || this.defaultColor;
  }

  private isProject(item: Project | Phase): item is Project {
    return 'color' in item && 'type' in item;
  }

  onAddProjectClick(): void {
    this.addProjectClicked.emit();
  }
}
