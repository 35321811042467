import { Tag, Record, RecordsGroup, RecordGroupList } from '../models/record-management.interface';

const mockTags: Tag[] = [
  {
    id: 'tag1',
    label: 'ML Models',
    description: 'Machine Learning model related records',
    color: '#4CAF50',
    subTags: [
      { id: 'tag1-1', label: 'Training', description: 'Model training related', color: '#81C784' },
      { id: 'tag1-2', label: 'Inference', description: 'Model inference related', color: '#66BB6A' }
    ],
    recordCount: 15
  },
  {
    id: 'tag2',
    label: 'Data',
    description: 'Data processing and storage',
    color: '#2196F3',
    subTags: [
      { id: 'tag2-1', label: 'Storage', description: 'Data storage solutions', color: '#64B5F6' },
      { id: 'tag2-2', label: 'Processing', description: 'Data processing pipelines', color: '#42A5F5' }
    ],
    recordCount: 10
  }
];

export class MockRecordManagementServer {
  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getRecordGroups(): Promise<RecordsGroup[]> {
    await this.delay(300);
    return [];
  }

  async getRecordGroupLists(): Promise<RecordGroupList[]> {
    await this.delay(300);
    return [];
  }

  async toggleGroupPin(groupId: string): Promise<void> {
    await this.delay(100);
  }

  async toggleGroupExpansion(groupId: string): Promise<void> {
    await this.delay(100);
  }

  getDataCollectionStage(): RecordGroupList {
    return {
      id: 'data-collection',
      title: 'Data Collection',
      defaultExpanded: true,
      groups: []
    };
  }

  getProcessingStage(): RecordGroupList {
    return {
      id: 'processing',
      title: 'Processing',
      defaultExpanded: true,
      groups: []
    };
  }

  getModelingStage(): RecordGroupList {
    return {
      id: 'modeling',
      title: 'Modeling',
      defaultExpanded: true,
      groups: []
    };
  }

  getProductionStage(): RecordGroupList {
    return {
      id: 'production',
      title: 'Production',
      defaultExpanded: true,
      groups: []
    };
  }

  async getFolderContent(path: string): Promise<RecordGroupList> {
    await this.delay(300); // Simulate network delay
    
    const parts = path.split('/').filter(p => p);
    const folderName = parts[parts.length - 1] || 'Root';
    const isRoot = path === 'path_/';
    
    // Mock folder structure based on path
    let subfolders: RecordsGroup[] = [];
    let records: RecordsGroup[] = [];

    if (isRoot) {
      subfolders = ['Data Collection', 'Processing', 'Modeling', 'Production'].map(name => ({
        id: `path_/${name.toLowerCase().replace(/\s+/g, '-')}/`,
        title: name,
        isPinned: false,
        isExpanded: false,
        date: new Date().toISOString(),
        tags: [],
        records: [],
        recommendedFlows: [],
        type: 'folder' as const
      }));
    } else if (path === 'path_/data-collection/') {
      subfolders = ['Raw Data', 'Preprocessed', 'Quality Reports'].map(name => ({
        id: `${path}${name.toLowerCase().replace(/\s+/g, '-')}/`,
        title: name,
        isPinned: false,
        isExpanded: false,
        date: new Date().toISOString(),
        tags: [],
        records: [],
        recommendedFlows: [],
        type: 'folder' as const
      }));
      
      records = [
        {
          id: `${path}data-source-1`,
          title: 'Customer Database Connection',
          isPinned: false,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'Configuration for customer database connection',
          tags: [{ 
            id: 'db', 
            label: 'Database', 
            color: '#2196F3',
            description: 'Database connection configuration'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        },
        {
          id: `${path}data-source-2`,
          title: 'Sales Data API Integration',
          isPinned: true,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'API configuration for sales data integration',
          tags: [{ 
            id: 'api', 
            label: 'API', 
            color: '#4CAF50',
            description: 'API integration settings'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        }
      ];
    } else if (path === 'path_/data-collection/raw-data/') {
      records = [
        {
          id: `${path}raw-1`,
          title: 'Customer Transactions 2024',
          isPinned: false,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'Raw customer transaction data for 2024',
          tags: [{ 
            id: 'data', 
            label: 'Raw Data', 
            color: '#FF9800',
            description: 'Unprocessed data files'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        },
        {
          id: `${path}raw-2`,
          title: 'Product Catalog Export',
          isPinned: false,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'Raw product catalog data export',
          tags: [{ 
            id: 'data', 
            label: 'Raw Data', 
            color: '#FF9800',
            description: 'Unprocessed data files'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        }
      ];
    } else if (path === 'path_/data-collection/preprocessed/') {
      records = [
        {
          id: `${path}prep-1`,
          title: 'Cleaned Transactions Dataset',
          isPinned: false,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'Preprocessed and cleaned transaction data',
          tags: [{ 
            id: 'data', 
            label: 'Preprocessed', 
            color: '#9C27B0',
            description: 'Cleaned and preprocessed data'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        },
        {
          id: `${path}prep-2`,
          title: 'Normalized Product Data',
          isPinned: false,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'Normalized and standardized product catalog',
          tags: [{ 
            id: 'data', 
            label: 'Preprocessed', 
            color: '#9C27B0',
            description: 'Cleaned and preprocessed data'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        }
      ];
    } else if (path === 'path_/data-collection/quality-reports/') {
      records = [
        {
          id: `${path}report-1`,
          title: 'Data Quality Assessment',
          isPinned: false,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'Comprehensive data quality report',
          tags: [{ 
            id: 'report', 
            label: 'Report', 
            color: '#FF5722',
            description: 'Analysis report'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        }
      ];
    } else if (path === 'path_/processing/') {
      subfolders = ['Transformations', 'Validation Rules', 'Output'].map(name => ({
        id: `${path}${name.toLowerCase().replace(/\s+/g, '-')}/`,
        title: name,
        isPinned: false,
        isExpanded: false,
        date: new Date().toISOString(),
        tags: [],
        records: [],
        recommendedFlows: [],
        type: 'folder' as const
      }));
      
      records = [
        {
          id: `${path}process-1`,
          title: 'Data Cleaning Pipeline',
          isPinned: false,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'Data cleaning and preprocessing pipeline',
          tags: [{ 
            id: 'pipeline', 
            label: 'Pipeline', 
            color: '#9C27B0',
            description: 'Data processing workflow'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        }
      ];
    } else if (path === 'path_/processing/transformations/') {
      records = [
        {
          id: `${path}transform-1`,
          title: 'Customer Data Transform',
          isPinned: false,
          isExpanded: false,
          date: new Date().toISOString(),
          description: 'Customer data transformation rules',
          tags: [{ 
            id: 'transform', 
            label: 'Transform', 
            color: '#00BCD4',
            description: 'Data transformation rules'
          }],
          records: [],
          recommendedFlows: [],
          type: 'record' as const
        }
      ];
    }

    return {
      id: path,
      title: isRoot ? 'Root Folder' : folderName,
      defaultExpanded: true,
      groups: [...subfolders, ...records]
    };
  }
}

const dataCollectionTags: Tag[] = [
  {
    id: 'infra-tag',
    label: 'Infrastructure',
    description: 'Infrastructure setup and management',
    color: '#FF9800',
    subTags: [
      { id: 'cloud-res', label: 'Cloud Resources', description: 'Cloud resource management', color: '#FFB74D' },
      { id: 'sec-access', label: 'Security & Access', description: 'Security and access control', color: '#FFA726' },
      { id: 'data-gov', label: 'Data Governance', description: 'Data governance policies', color: '#FF9100' },
      { id: 'monitoring', label: 'Monitoring', description: 'Monitoring setup and configuration', color: '#FF6D00' }
    ],
    recordCount: 5
  },
  {
    id: 'data-src',
    label: 'Data Sources',
    description: 'Data source integration',
    color: '#2196F3',
    subTags: [
      { id: 'ext-api', label: 'External APIs', description: 'External API integrations', color: '#64B5F6' },
      { id: 'db-conn', label: 'Database Connections', description: 'Database connection management', color: '#42A5F5' },
      { id: 'file-sys', label: 'File Systems', description: 'File system integrations', color: '#1E88E5' }
    ],
    recordCount: 4
  },
  {
    id: 'quality',
    label: 'Quality Control',
    description: 'Data quality control setup',
    color: '#4CAF50',
    subTags: [
      { id: 'val-rules', label: 'Validation Rules', description: 'Data validation rules', color: '#81C784' },
      { id: 'profiling', label: 'Data Profiling', description: 'Data profiling configuration', color: '#66BB6A' },
      { id: 'err-handle', label: 'Error Handling', description: 'Error handling setup', color: '#4CAF50' }
    ],
    recordCount: 4
  }
];

const processingTags: Tag[] = [
  {
    id: 'transform',
    label: 'Data Transformation',
    description: 'Data transformation and feature engineering',
    color: '#3F51B5',
    subTags: [
      { id: 'cleansing', label: 'Cleansing', description: 'Data cleansing operations', color: '#7986CB' },
      { id: 'feature-eng', label: 'Feature Engineering', description: 'Feature engineering processes', color: '#5C6BC0' },
      { id: 'enrichment', label: 'Data Enrichment', description: 'Data enrichment operations', color: '#3949AB' }
    ],
    recordCount: 4
  },
  {
    id: 'business',
    label: 'Business Rules',
    description: 'Business rules and calculations',
    color: '#009688',
    subTags: [
      { id: 'segmentation', label: 'Segmentation', description: 'Segmentation rules', color: '#4DB6AC' },
      { id: 'calculation', label: 'Calculations', description: 'Calculation methods', color: '#26A69A' },
      { id: 'constraints', label: 'Constraints', description: 'Business constraints', color: '#00897B' }
    ],
    recordCount: 4
  },
  {
    id: 'performance',
    label: 'Performance',
    description: 'Performance optimization',
    color: '#FFC107',
    subTags: [
      { id: 'query-opt', label: 'Query Optimization', description: 'Query performance optimization', color: '#FFD54F' },
      { id: 'resources', label: 'Resources', description: 'Resource allocation', color: '#FFCA28' },
      { id: 'caching', label: 'Caching', description: 'Caching strategies', color: '#FFB300' }
    ],
    recordCount: 3
  }
];

const modelingTags: Tag[] = [
  {
    id: 'architecture',
    label: 'Model Architecture',
    description: 'Model architecture and configuration',
    color: '#673AB7',
    subTags: [
      { id: 'model-sel', label: 'Model Selection', description: 'Model selection process', color: '#9575CD' },
      { id: 'param-conf', label: 'Parameters', description: 'Parameter configuration', color: '#7E57C2' },
      { id: 'val-strat', label: 'Validation', description: 'Validation strategy', color: '#5E35B1' }
    ],
    recordCount: 4
  },
  {
    id: 'training',
    label: 'Training Pipeline',
    description: 'Model training and preprocessing',
    color: '#E91E63',
    subTags: [
      { id: 'preprocess', label: 'Preprocessing', description: 'Data preprocessing steps', color: '#F06292' },
      { id: 'train', label: 'Training', description: 'Model training process', color: '#EC407A' },
      { id: 'eval', label: 'Evaluation', description: 'Performance evaluation', color: '#D81B60' }
    ],
    recordCount: 4
  },
  {
    id: 'validation',
    label: 'Model Validation',
    description: 'Model testing and validation',
    color: '#795548',
    subTags: [
      { id: 'test-proc', label: 'Testing', description: 'Testing procedures', color: '#A1887F' },
      { id: 'perf-met', label: 'Performance', description: 'Performance metrics', color: '#8D6E63' },
      { id: 'bus-val', label: 'Business Validation', description: 'Business validation steps', color: '#6D4C41' }
    ],
    recordCount: 4
  }
];

const productionTags: Tag[] = [
  {
    id: 'deployment',
    label: 'Deployment',
    description: 'Deployment management and configuration',
    color: '#2196F3',
    subTags: [
      { id: 'env-setup', label: 'Environment', description: 'Environment setup', color: '#64B5F6' },
      { id: 'version-ctrl', label: 'Version Control', description: 'Version control management', color: '#42A5F5' },
      { id: 'release-mgmt', label: 'Release', description: 'Release management', color: '#1E88E5' }
    ],
    recordCount: 4
  },
  {
    id: 'monitoring',
    label: 'Monitoring',
    description: 'Performance monitoring and metrics',
    color: '#9C27B0',
    subTags: [
      { id: 'sys-monitor', label: 'System', description: 'System monitoring', color: '#BA68C8' },
      { id: 'model-perf', label: 'Model Performance', description: 'Model performance tracking', color: '#AB47BC' },
      { id: 'bus-metrics', label: 'Business Metrics', description: 'Business metrics tracking', color: '#8E24AA' }
    ],
    recordCount: 4
  },
  {
    id: 'maintenance',
    label: 'Maintenance',
    description: 'System maintenance and optimization',
    color: '#FF5722',
    subTags: [
      { id: 'update-proc', label: 'Updates', description: 'Update procedures', color: '#FF8A65' },
      { id: 'resource-opt', label: 'Resources', description: 'Resource optimization', color: '#FF7043' },
      { id: 'doc-mgmt', label: 'Documentation', description: 'Documentation management', color: '#F4511E' }
    ],
    recordCount: 4
  }
];

const mockTagsExtended: Tag[] = [...mockTags, ...dataCollectionTags, ...processingTags, ...modelingTags, ...productionTags];

const mockRecords: Record[] = [
  {
    id: 'rec1',
    icon: 'trending_up',
    name: 'Revenue Prediction',
    description: 'ML model for revenue prediction',
    tags: [mockTags[0]],
    recommendedFlows: [
      { id: 'flow1', name: 'Train Model', description: 'Train the revenue prediction model' },
      { id: 'flow2', name: 'Make Prediction', description: 'Use model to make predictions' }
    ]
  },
  {
    id: 'rec2',
    icon: 'storage',
    name: 'Data Pipeline',
    description: 'Data processing pipeline',
    tags: [mockTags[1]],
    recommendedFlows: [
      { id: 'flow3', name: 'Process Data', description: 'Run the data processing pipeline' }
    ]
  }
];

const mockGroups: RecordsGroup[] = [
  {
    id: 'group1',
    title: 'Revenue Analysis',
    description: 'Revenue prediction and analysis',
    date: '24/11/24',
    isPinned: true,
    isExpanded: true,
    tags: [mockTags[0], mockTags[1]],
    records: [mockRecords[0], mockRecords[1]],
    recommendedFlows: [
      { id: 'flow4', name: 'Analyze customer segments for MVP features', description: 'Segment analysis' },
      { id: 'flow5', name: 'Create event monitoring dashboard', description: 'Dashboard creation' }
    ]
  },
  {
    id: 'group2',
    title: 'Data Processing',
    description: 'Data processing workflows',
    date: '23/11/24',
    isPinned: false,
    isExpanded: false,
    tags: [mockTags[1]],
    records: [mockRecords[1]],
    recommendedFlows: [
      { id: 'flow6', name: 'Set up real-time analytics pipeline', description: 'Pipeline setup' }
    ]
  }
];

export interface RecommendedFlow {
  id: string;
  name: string;
  description: string;
  project?: string;
  phase?: string;
}

export interface ProjectFlows {
  general: RecommendedFlow[];
  [phase: string]: RecommendedFlow[];
}

export interface RecommendedFlowsData {
  default: RecommendedFlow[];
  [project: string]: ProjectFlows | RecommendedFlow[];
}

export const RECOMMENDED_FLOWS: RecommendedFlowsData = {
  // Default workspace flows
  default: [
    { 
      id: 'flow-default-1', 
      name: 'Workspace Overview', 
      description: 'View overview of all artifacts and their types' 
    },
    { 
      id: 'flow-default-2', 
      name: 'Recent Activities', 
      description: 'View recent artifact updates and queries' 
    }
  ],

  // Artifact processing flows organized by phase
  artifact: {
    general: [
      {
        id: 'flow-artifact-general-1',
        name: 'Artifact Dashboard',
        description: 'Overview of all artifacts and their processing status',
        project: 'artifact'
      },
      {
        id: 'flow-artifact-general-2',
        name: 'Processing History',
        description: 'View history of artifact processing and modifications',
        project: 'artifact'
      }
    ],
    'define-artifact-type': [
      {
        id: 'flow-define-1',
        name: 'Select Existing Data Type',
        description: 'Choose from available artifact type templates',
        project: 'artifact',
        phase: 'define-artifact-type'
      },
      {
        id: 'flow-define-2',
        name: 'Create New Data Type',
        description: 'Define a new custom artifact type',
        project: 'artifact',
        phase: 'define-artifact-type'
      },
      {
        id: 'flow-define-3',
        name: 'Suggest Data Type Definition',
        description: 'Get AI suggestions for artifact type structure',
        project: 'artifact',
        phase: 'define-artifact-type'
      }
    ],
    'upload-artifact': [
      {
        id: 'flow-upload-1',
        name: 'Upload New Artifact',
        description: 'Upload and validate new artifact data',
        project: 'artifact',
        phase: 'upload-artifact'
      },
      {
        id: 'flow-upload-2',
        name: 'Batch Upload',
        description: 'Upload multiple artifacts in batch',
        project: 'artifact',
        phase: 'upload-artifact'
      },
      {
        id: 'flow-upload-3',
        name: 'Validate Artifacts',
        description: 'Validate uploaded artifacts against their type definition',
        project: 'artifact',
        phase: 'upload-artifact'
      }
    ],
    'describe-artifact': [
      {
        id: 'flow-describe-1',
        name: 'Add Metadata',
        description: 'Add descriptive metadata to artifacts',
        project: 'artifact',
        phase: 'describe-artifact'
      },
      {
        id: 'flow-describe-2',
        name: 'Tag Management',
        description: 'Manage artifact tags and categories',
        project: 'artifact',
        phase: 'describe-artifact'
      },
      {
        id: 'flow-describe-3',
        name: 'Generate Description',
        description: 'Auto-generate artifact descriptions using AI',
        project: 'artifact',
        phase: 'describe-artifact'
      }
    ],
    'query-related-artifacts': [
      {
        id: 'flow-query-1',
        name: 'Search Artifacts',
        description: 'Search and filter artifacts by various criteria',
        project: 'artifact',
        phase: 'query-related-artifacts'
      },
      {
        id: 'flow-query-2',
        name: 'Find Similar',
        description: 'Find artifacts similar to selected ones',
        project: 'artifact',
        phase: 'query-related-artifacts'
      },
      {
        id: 'flow-query-3',
        name: 'Relationship Graph',
        description: 'Visualize relationships between artifacts',
        project: 'artifact',
        phase: 'query-related-artifacts'
      }
    ]
  }
};
