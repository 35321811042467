import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../../../services/user.service';
import { SessionsService } from '../../../services/sessions.service';
import { User } from '../../../models/user.interface';
import { Project, Phase, TabInfo } from '../../../models/session.interface';
import { Subscription, firstValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NavigationBarComponent } from '../navigation-bar/navigation-bar.component';
import { TabsListComponent } from '../tabs-list/tabs-list.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule, 
    MatButtonModule, 
    MatIconModule, 
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    NavigationBarComponent,
    TabsListComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User | null = null;
  private userSubscription?: Subscription;
  private projectsSubscription?: Subscription;
  private phasesSubscription?: Subscription;
  
  projects: Project[] = [];
  currentPhases: Phase[] = [];
  selectedProject: Project | null = null;
  selectedPhase: Phase | null = null;

  constructor(
    private authService: MsalService,
    private userService: UserService,
    private sessionsService: SessionsService
  ) {}
  
  async ngOnInit(): Promise<void> {
    await this.userService.waitForTokenReady();
    
    this.userSubscription = this.userService.user$.subscribe(
      user => this.user = user
    );

    await this.sessionsService.loadInitialData();

    this.projectsSubscription = this.sessionsService.getProjects().subscribe(
      projects => this.projects = projects
    );

    this.sessionsService.getSelectedProject().subscribe(
      project => {
        this.selectedProject = project;
        if (project) {
          this.sessionsService.getPhases(project.color).then(
            phases => this.currentPhases = phases
          );
        } else {
          this.currentPhases = [];
        }
      }
    );

    this.phasesSubscription = this.sessionsService.getSelectedPhase().subscribe(
      phase => this.selectedPhase = phase
    );
  }

  async onProjectSelected(item: Project | Phase) {
    if (this.isProject(item)) {
      // Get active session
      const tabs = await firstValueFrom(this.sessionsService.getTabs());
      const activeTab = tabs.find(tab => tab.active);
      
      if (activeTab) {
        // Try to update the session's project and phase
        const updated = await this.sessionsService.updateSessionProjectAndPhase(activeTab.sessionId, item, null, true);
        
        // If update failed (session has messages), create a new session
        if (!updated) {
          const user = await this.userService.getCurrentUser();
          if (user?.email) {
            await this.sessionsService.startSessionWithProject(user.email, item);
          }
        }
      } else {
        // If no active tab, just update the header
        await this.sessionsService.selectProject(item);
      }
    }
  }

  onPhaseSelected(item: Project | Phase) {
    if (this.isPhase(item)) {
      // Get current project
      const currentProject = this.selectedProject;
      if (!currentProject) return;

      // Get active session
      this.sessionsService.getTabs().pipe(
        take(1),
        map((tabs: TabInfo[]) => tabs.find(tab => tab.active))
      ).subscribe(async activeTab => {
        if (activeTab) {
          // Try to update the session's project and phase
          const updated = await this.sessionsService.updateSessionProjectAndPhase(
            activeTab.sessionId, 
            currentProject, 
            item,
            true // Skip header update to prevent recursion
          );

          // If update failed (session has messages), create a new session
          if (!updated) {
            const user = await this.userService.getCurrentUser();
            if (user?.email) {
              await this.sessionsService.startSessionWithProject(user.email, currentProject, item);
            }
          }
        } else {
          // If no active tab, just update the header
          this.sessionsService.selectPhase(item);
        }
      });
    }
  }

  private isProject(item: Project | Phase): item is Project {
    return 'color' in item && 'type' in item;
  }

  private isPhase(item: Project | Phase): item is Phase {
    return 'projectId' in item;
  }

  onSearchFocus() {
    // You could add additional focus behavior here
  }

  onSearchBlur() {
    // You could add additional blur behavior here
  }

  onAddProject(): void {
    this.sessionsService.addProject();
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.projectsSubscription?.unsubscribe();
    this.phasesSubscription?.unsubscribe();
  }

  logout() {
    this.userService.clearUser();
    this.authService.logoutRedirect();
  }
}